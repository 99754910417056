import * as React from 'react'
// import { Link } from 'gatsby'
import Slider from 'react-slick'
import Unit from '../components/Unit'

// function SampleNextArrow(props) {
//   const { className, style, onClick } = props
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: 'block', background: 'black' }}
//       onClick={onClick}
//     />
//   )
// }

// function SamplePrevArrow(props) {
//   const { className, style, onClick } = props
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: 'block', background: 'green' }}
//       onClick={onClick}
//     />
//   )
// }

const Objekti = ({ kategorija, proizvodiKategorija, boja, jezik }) => {
  // console.log('BOJA: ' + boja)
  // Slider settings
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    lazyLoad: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
          dots: false,
        },
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          adaptiveHeight: true,
          initialSlide: 0,
          infinite: false,
        },
      },
    ],
  }

  return (
    <section className={'p-8 sekcija-' + boja}>
      <h3
        className={`text-2xl font-playfair italic text-white text-center mb-6 text-${boja}`}>
        {kategorija}
      </h3>
      <Slider {...sliderSettings}>
        {proizvodiKategorija.map((product, index) => (
          <Unit key={index} unit={product} boja={boja} jezik={jezik} />
        ))}
      </Slider>
    </section>
  )
}
export default Objekti
